import {
  defineNuxtRouteMiddleware,
  useRuntimeConfig,
  navigateTo,
  useNuxtApp,
  useAuth,
} from '#imports'
import { useSubscription } from '~/composables/useSubscription'

export const updateUserData = async (
  store: any,
  $sentryCaptureException: Function,
) => {
  try {
    await store.dispatch('updateUserData')
  } catch (error) {
    $sentryCaptureException(error)
  }
}

export default defineNuxtRouteMiddleware(async (_, { fullPath, query }) => {
  const {
    public: { BASIC_AND_PREMIUM },
  } = useRuntimeConfig()
  const { $sentryCaptureException, $store } = useNuxtApp()
  const paymentType = $store.getters['subscriptions/cart']?.paymentType
  const { data }: any = useAuth()
  const userHasSubscription = data.value?.currentMonthSubscription
  const { isSubscriptionUpdate } = useSubscription()

  if (query?.autoRenewal && !data.value) {
    const cookie = useCookie('redirect', {
      maxAge: 300,
    })
    cookie.value = fullPath
    return navigateTo({ path: '/inloggen', query }, { redirectCode: 302 })
  }

  if (
    BASIC_AND_PREMIUM === '1' &&
    !userHasSubscription &&
    isSubscriptionUpdate(paymentType)
  ) {
    return await updateUserData($store, $sentryCaptureException)
  }

  if (BASIC_AND_PREMIUM !== '1' || !userHasSubscription) {
    return navigateTo({
      path: 'betalen',
      query,
    })
  }
})
